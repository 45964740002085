import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./footer.css"

const Footer = ({ siteTitle }) => (
  <footer>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `.5rem 0rem .5rem 0rem`,
      }}
    >
      <div style = {{textAlign: "center", fontSize: "14px"}}>
        © {new Date().getFullYear()}, Built with <a style={{color:"#797986"}} href="https://www.gatsbyjs.com">Gatsby</a>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
