import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.css"

const Header = ({ siteTitle }) => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `.6rem 0rem .3rem 1rem`,
      }}
    >
      <h1 style={{ margin: "0" , padding: "0rem 0rem .25rem 0rem"}}>
        <Link
          to="/"
          style={{
            color: `#e0ebeb	`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>

      <ul>
        <li>
          <Link to="/" activeStyle={{ color: "white" }}>
            home
          </Link>
        </li>
        <li>|</li>
        <li>
          <Link to="/posts" activeStyle={{ color: "white" }}>
            posts
          </Link>
        </li>
        <li>|</li>
        <li>
          <Link to="/reference" activeStyle={{ color: "white" }}>
            reference
          </Link>
        </li>
        <li>|</li>
        <li>
          <Link to="/data" activeStyle={{ color: "white" }}>
            data
          </Link>
        </li>
        <li>|</li>
        <li>
          <Link to="/contact" activeStyle={{ color: "white" }}>
            contact
          </Link>
        </li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
